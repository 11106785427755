// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  {
    title: '💠 Chapter One: Origins 💠',
    message: `Long ago in the wild lands of Koumaira there lived a tribe of Vixen hidden deep within the enchanted Blossom forest. No one knows how they came to be, but historical records show they were simple hunter-gatherers who lived in harmony with nature and the forest spirits, occasionally making trips to nearby towns to trade goods, typically bringing with them a unique type of handwoven basket from the Blossom tree fiber.

The Vixen enjoyed this idyllic lifestyle for many hundreds of years, traditions were passed down from generation to generation, their customs and warrior spirit grew strong and their noble reputation preceded them throughout the world. Many notable anthropologists of the time would visit the Blossom forest to study their way of life and were always received with great hospitality.

Meanwhile, in Koumaira, things were changing, and rapidly so. It began in the cities with the discovery of steam-powered technology. Factories began to be constructed, extracting mineral wealth from the earth and the process of the industrialization was evidently taking place. During this period much wealth was created and new secret societies began to emerge and establish their influence in the economy and political sphere.

The vixens, isolated from city business, didn’t feel the full effect of the change. But the Elders — in communication with the spirits — could sense the energy going in a dark direction and at once began making preparations. Soon signs of decay were showing in the Blossom forest, and it became obvious to the rest of the tribe.

Pollution from the newly built railway system was affecting the trees, the bright, ripe berries of previous years were now yellow and had a bitter taste to them. The bark of the trees began to dampen and wither, the forest spirits were enraged.

As time went by the previous utopia became a shadow of its former self and the council of elders had no choice but to meet and discuss the future of the tribe. It was clear to them that not everyone would be able to inhabit the forest for much longer. The atmosphere in the meeting was heavy and foreboding, but at the same time the Vixens were inspired to preserve and fight for their way of life.

The tribe would split into four allied factions, the elders and children would stay in the forest and tend their ancestral homeland and maintain their connection with the spirits, the more brave and hardened would migrate westwards to the desert lands where a few vixens had already established a humble trading colony.

The majority of the tribe would head northwards to the capital city of Koumaira and integrate with the humans and adapt to their way of life, relaying information to the elders. The final group would journey southwards to an ancient coastal city where the most notorious warriors lived and would train with them and learn their secret martial techniques.

And so it was decided, that from that day that the Vixens had a mission. It was a willing sacrifice they would take in order to restore peace to the world, from that day onwards a new fire was ignited in the heart of every Vixen… ❤️‍🔥`,
    link: '#',
    image: 'https://vixenlegends.com/images/vp.png',
  },
  {
    title: 'Chapter 2: Eternal Blossom 🌸',
    message: `It was late spring in the Blossom Forest and the trees were beginning to flower into deep shades of lilac and lavender. Things seemed pleasant on this particular morning and the village was full of laughter and merriment, almost forgetting the troubles of the previous years. Bib had woken up late that day after a long night on watch duty at the western end of the forest. There was seldom to report, it was quiet as usual, and he occupied himself studying the constellations in the sky or gazing into the horizon towards the river looking at the fishing boats and their hanging lanterns. He had been worried lately with all the talk of war and the exodus of many of his kinfolk but that morning as he left his hut he was surprised by the general atmosphere of festiveness.
    
    Passing through the row of trees adjacent to his house he saw that a few of the younger vixens had lit a small fire and were holding torches, dancing around it and chanting the old songs of the village. He realised that today was the annual celebration of Eternal Blossom and preparations had already begun for what was to take place later that evening.

The Tree of Eternal Blossom was the oldest of all the trees in the forest and it grew to an incredible height. When the ancestors of the vixens first arrived there it was the power of this tree that drew them and around it they began to build their settlements, never straying too far from its mystical energy. Indeed so accustomed had they become to the tree that they barely noticed it anymore but once every year all the village would gather around it in the early evening with gifts and offerings. Remembering this Bib went at once to the north end of the village where the candle maker lived and there he bought two red candles which he would use for the ceremony.

He returned home to his hut still tired and spent the rest of the afternoon sat by the window looking over the street; sharpening his weapons and mending his clothes. As the sun was setting he noticed many of his neighbors slowly and silently making their way towards the event. He quickly put on his robe and made ready to leave, taking with him only his candles and the axe which never left his side.

The evening air was still warm and a gentle wind was coming from the north. The mood was dreamlike, only the rustling of leaves could be heard and the low hoots of owls coming from the lower branches of the trees. The owls would always journey to the forest on this day although they would merely watch from a distance. Bib walked past the small fire he had seen earlier which was now reduced to a faint glow, it gave a pleasant crackling sound as he drew closer.

He stooped over with one candle in each hand and held them so that their wicks fell right above the remaining embers. After a few seconds they caught light and with after a quick spark both flames turned pure red and their figures now sharply resembled that of two trees, if Bib looked closely he could even make out the individual leaves waving from some invisible breeze. For the candle maker was once a renowned sage but now in his latter years had retired and dedicated himself to the craft of candle making, to which he generously added his own spells. Though commonplace to the vixens, these were highly sought-after items especially among the merchants of the south who delighted in such enchanted objects.

Slowly moving his gaze away from the flames he continued along the path that was now twisting and winding around many of the older trees as it approached the center of the forest. Here the canopies stretched out further and looming above him were the numerous tree-houses that comprised the town center. Suddenly he found himself in an ever-growing crowd, with everyone bearing different forms of fire, and even an elder who had a blue ember hovering in each of his cupped hands. As they made their way more and more were emerging from between the trees or hopping down from branches to join them in what had now become a procession. It was now completely dark and after some time the path began to widen and tall wooden torches had been placed on either side leading up to the main opening of the forest. The path finally joined the clearing and there they were met with the sight of hundreds of Vixens already seated in groups and speaking in hushed voices waiting for the ceremony to begin.

To his disappointment this wasn’t the typical festive celebration he was used to with wine, music and laughter. He quickly glanced around hoping to find some of his friends, but almost everyone had drawn their hoods and had their attention turned towards the tree. Not wanting to get in the way he moved further along the edge of the clearing and found some space around a smaller tree. Just to the left of it was a makeshift stand from which they had been serving tea although no one was there now.

He went up to the large cauldron that was slowly simmering on a fire and lifted the lid. It was blossom tea, a traditional drink made by brewing mint leaves and blossom leaves and then adding honey and sugar which was left to cook overnight. It tasted particularly good at this time of year when the blossom leaves were at their most fragrant. Bib took one of the bowls and filled it to the brim then went to his seat, leaning back against the tree and placing his candles on either side of him.

As he drank, the sweet blossom tea filled him with warmth, and he began to settle in and observe the scene that was now unfolding.

Towering in front of him was the tree of eternal blossom, it was draped in tapestries and lanterns had been hung from all of its branches to mark the occasion.
At its base stood a few soldiers clad in ceremonial gear holding spears across their chests, on the staircase that went around the tree he could more soldiers who were seated and gazing solemnly into the crowd.
Just before the staircase wound up into the tree’s upper levels it led to a wide platform that was in clear view of the crowd.
 
Here it looked like there was some activity, a couple of the elders were gathered and walking back and forth in discussion, but nothing could be heard by the onlookers. Further along, the platform deepened into hollow and this was where the main council was being held.

There was the general of the woodland army speaking with someone Bib had never seen before, a vixen with unique colors that shifted in the light. Then to Bib’s surprise he saw the candle maker sitting in the furthest corner and laid out before him were a bundle of scrolls.

Just then two soldiers came up the staircase carrying a decorated chest and a murmur broke out in the crowd, eventually reaching the platform they lowered it gently to the floor, with that the chief of the elders stood up and signalled that the ceremony was to begin.`,
    link: '#',
    image: 'images/blossom.png',
  },
];
